import { useState, useEffect, useMemo } from "react";
import { Box, Fade, Modal, Typography, useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import LinearProgress from "@mui/material/LinearProgress";
import Carousel from "react-material-ui-carousel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import io from "socket.io-client";
import "../App.css";
import bullionStats from "../assets/bullioStats.png";
import leenLogo from "../assets/leen-logo.svg";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import SubscriptionExpired from "../componets/SubscriptionExpired";
import SubscriptionExpiringSoon from "../componets/SubscriptionExpiringSoon";
import MarketClose from "../componets/MarketClose";
const { makeStyles } = require("@mui/styles");
const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    backgroundColor: "#16341B",
    padding: "3rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  banner: {
    gridArea: "banner",
    borderTopLeftRadius: "3rem",
    borderTopRightRadius: "3rem",
    height: "30%",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    overflow: "hidden",
  },
  spotRate: {
    gridArea: "spotRate",
    backgroundColor: "#BEC0C0",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
    height: "45%",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  stat: {
    height: "15%",
    gridArea: "stat",
    backgroundColor: "#2B3E2E",
    borderBottomLeftRadius: "3rem",
    borderBottomRightRadius: "3rem",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  bullionStatsImg: {
    width: "150px",
    height: "auto",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    gridTemplateColumns: "1fr",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    gridArea: "header",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "18%",
  },
  leenLogoImg: {
    width: "35%",
    height: "auto",
  },
  table: {
    height: "60%",
    gridArea: "table",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 6fr",
    gridTemplateAreas: `
        'tabeHeader' 
        'tableContent'
      `,
    gap: "1em",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#BEC0C0",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#16341B",
  },
  tableContent: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar
    },
    gridArea: "tableContent",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    overflowX: "scroll",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "flex-start",
    fontSize: "2vw",
    paddingLeft: "2rem",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "15%",
    flexGrow: 1,
    gridArea: "tableRow1",
    border: "1px dotted #5C3F32",
    borderRadius: "5px",
    background: "#2B3E2E",
    display: "flex",
  },
  updates: {
    height: "15%",
    gridArea: "updates",
    backgroundColor: "#2B3E2E",
    borderBottomLeftRadius: "3rem",
    borderBottomRightRadius: "3rem",
    color: "#fff",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  updatesHeader: {
    background: "#BEC0C0",
    color: "#000",
    borderBottom: "5px solid #506253",
  },
  updatesContent: {
    display: "flex",
    color: "#fff",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    height: "100%",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [render, setRender] = useState(true);
  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });

  const [isMarketClosed, setIsMarketClosed] = useState(false);
  const [adminData, setAdminData] = useState();
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [time, setTime] = useState(new Date());
  const [goldNews, setGoldNews] = useState({});
  const [openExpireSoon, setOpenExpireSoon] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  useEffect(() => {
    handleFindSocketUrl();
    handleFinsCommodities();
    handleFindSpread();
    handleFindRatio();
    hadlefetchNews();
    handelFindBanners();
    handleFindGoldPriceNews();
    handleFindAdminProfile();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err}`);
      });
      socket.on("error", (err) => {
        console.log(`error due to ${err}`);
      });
      socket.on("gold-rate-change", (data) => {
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });

    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketClosed(true);
      } else {
        setIsMarketClosed(false);
      }
    }, 100); // Check every minute
    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleExpireSoonOpen = () => setOpenExpireSoon(true);
  const handleExpireSoonClose = () => setOpenExpireSoon(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      });
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      if (!socket) {
        socket = io(res?.socketURL);
        Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
      }
    }
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (date) => {
    return date.toLocaleString([], {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  ////////////Block//////
  const [openBlocked, setOpenBlocked] = useState(false);
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ////////////////////////////////////////////////////////////

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      checkSubscriptionExpireSoon(adminData);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  ////////////////////////////////////////////////////////////

  return (
    <div className={openSubscriptionExpierd && classes.blur}>
      <Box className={classes.mainContainer}>
        {isMarketClosed && <MarketClose timeZone={adminData?.time_zone} />}
        <Box className={classes.leftPart}>
          <Box className={classes.banner}>
            <Carousel
              animation="fade" // Set the animation type to slide
              navButtonsAlwaysVisible={false} // Show navigation buttons always
              interval={10000}
              indicatorContainerProps={{
                style: {
                  display: "none",
                },
              }}
              sx={{
                maxHeight: "26vh",
                borderTopLeftRadius: "3rem",
                borderTopRightRadius: "3rem",
              }}
              transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
              autoPlay
              duration={2000}
            >
              {banners?.map((banner, i) => (
                <img
                  style={{
                    width: "100%",
                    height: "26vh",
                    objectFit: "cover",
                    borderTopLeftRadius: "3rem",
                    borderTopRightRadius: "3rem",
                  }}
                  src={banner.imageUrl}
                  alt="banner"
                />
              ))}
            </Carousel>
          </Box>
          <Box className={classes.spotRate}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "23%",
                borderBottom: "1px dotted white",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.4vw",
                  fontWeight: "bold",
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                SPOT RATE
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Box
                  sx={{
                    color: "#fff",
                    background: "#16341B",
                    px: "7px",
                    display: "inline-block",
                    width: "fit-content",
                    borderRadius: "4px",
                  }}
                >
                  <Typography style={{ fontSize: "1vw", fontWeight: "bold" }}>
                    $
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                  {displayBidOrBuy.bidOrBuy}
                </Typography>
                <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Box
                  sx={{
                    color: "#fff",
                    background: "#16341B",
                    px: "7px",
                    display: "inline-block",
                    width: "fit-content",
                    borderRadius: "4px",
                  }}
                >
                  <Typography style={{ fontSize: "1vw", fontWeight: "bold" }}>
                    $
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                  {displayBidOrBuy.askOrSell}
                </Typography>
                <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "42%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2vw",
                  fontWeight: "bold",
                  marginBottom: 4.3,
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                GOLD
              </Typography>

              <Box className={classes.spotRateBoxGoldRow2Col1}>
                <Box
                  sx={{
                    background:
                      Number(gold?.cur?.bid).toFixed(2) <
                      Number(gold?.pre?.bid).toFixed(2)
                        ? "#DE5B56"
                        : Number(gold?.cur?.bid).toFixed(2) >
                          Number(gold?.pre?.bid).toFixed(2)
                        ? "#5FA147"
                        : "#2F2E2C",
                    border: "1px solid #fff",
                    color: "#fff",
                    borderRadius: "5px",
                    width: "7.3vw",
                    transition: "background-color 0.5s ease",
                    height: "6.3vh",
                    display: "flex",
                    alignItems: "center",
                    px: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                    {gold?.cur?.bid
                      ? (
                          Number(spread.goldBidSpread) + Number(gold?.cur?.bid)
                        ).toFixed(2)
                      : "0.00"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    mt: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      background: "#DE5B56",
                      color: "#fff",
                      px: 1,
                      display: "inline-block",
                      width: "fit-content",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                  </Box>
                  <Typography style={{ fontSize: "1.3vw" }}>
                    {gold.cur?.lowPrice
                      ? (
                          Number(spread.goldLowSpread) +
                          Number(gold.cur?.lowPrice)
                        ).toFixed(2)
                      : "0.00"}
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.spotRateBoxGoldRow2Col2}>
                <Box
                  sx={{
                    background:
                      Number(gold?.cur?.ask).toFixed(2) <
                      Number(gold?.pre?.ask).toFixed(2)
                        ? "#DE5B56"
                        : Number(gold?.cur?.ask).toFixed(2) >
                          Number(gold?.pre?.ask).toFixed(2)
                        ? "#5FA147"
                        : "#2F2E2C",
                    border: "1px solid #fff",
                    color: "#fff",
                    transition: "background-color 0.5s ease",
                    borderRadius: "5px",
                    width: "7.3vw",
                    height: "6.3vh",
                    display: "flex",
                    alignItems: "center",
                    px: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                    {gold?.cur?.ask
                      ? (
                          Number(spread.goldAskSpread) + Number(gold?.cur?.ask)
                        ).toFixed(2)
                      : "0.00"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    mt: 0.5,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      background: "#5FA147",
                      color: "#fff",
                      px: 1,
                      display: "inline-block",
                      width: "fit-content",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw" }}>High</Typography>
                  </Box>
                  <Typography style={{ fontSize: "1.3vw" }}>
                    {gold?.cur?.highPrice
                      ? (
                          Number(spread.goldHighSpread) +
                          Number(gold?.cur?.highPrice)
                        ).toFixed(2)
                      : "0.00"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "42%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.6vw",
                  marginBottom: 4.3,
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                SILVER
              </Typography>

              <Box className={classes.spotRateBoxSilverRow2Col1}>
                <Box
                  sx={{
                    transition: "background-color 0.5s ease",
                    background:
                      Number(silver?.cur?.bid).toFixed(2) <
                      Number(silver?.pre?.bid).toFixed(2)
                        ? "#DE5B56"
                        : Number(silver?.cur?.bid).toFixed(2) >
                          Number(silver?.pre?.bid).toFixed(2)
                        ? "#5FA147"
                        : "#2F2E2C",
                    border: "1px solid #fff",
                    color: "#fff",
                    borderRadius: "5px",
                    px: 1.5,
                    width: "7.3vw",
                    height: "6.3vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "2vw" }}>
                    {silver?.cur?.bid
                      ? (
                          Number(spread.silverBidSpread) +
                          Number(silver?.cur?.bid)
                        ).toFixed(3)
                      : "0.000"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    mt: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      background: "#DE5B56",
                      color: "#fff",
                      px: 1,
                      display: "inline-block",
                      width: "fit-content",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                  </Box>
                  <Typography style={{ fontSize: "1vw" }}>
                    {silver?.cur?.lowPrice
                      ? (
                          Number(spread.silverLowSpread) +
                          Number(silver?.cur?.lowPrice)
                        ).toFixed(3)
                      : "0.000"}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.spotRateBoxSilverRow2Col2}>
                <Box
                  sx={{
                    transition: "background-color 0.5s ease",
                    background:
                      Number(silver?.cur?.ask).toFixed(2) <
                      Number(silver?.pre?.ask).toFixed(2)
                        ? "#DE5B56"
                        : Number(silver?.cur?.ask).toFixed(2) >
                          Number(silver?.pre?.ask).toFixed(2)
                        ? "#5FA147"
                        : "#2F2E2C",
                    border: "1px solid #fff",
                    color: "#fff",
                    borderRadius: "5px",
                    px: 1.5,
                    width: "7.3vw",
                    height: "6.3vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      // fontSize: "1.6vw"
                    }}
                  >
                    {silver?.cur?.ask
                      ? (
                          Number(spread.silverAskSpread) +
                          Number(silver?.cur?.ask)
                        ).toFixed(3)
                      : "0.000"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    mt: 0.5,
                    // pl: 1,
                  }}
                >
                  <Box
                    sx={{
                      background: "#5FA147",
                      color: "#fff",
                      px: 1,
                      display: "inline-block",
                      width: "fit-content",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw" }}>High</Typography>
                  </Box>
                  <Typography style={{ fontSize: "1vw" }}>
                    {silver?.cur?.highPrice
                      ? (
                          Number(spread.silverHighSpread) +
                          Number(silver?.cur?.highPrice)
                        ).toFixed(3)
                      : "0.000"}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.stat}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                px: 5,
                py: 1,
                boxSizing: "border-box",
              }}
            >
              <Typography sx={{ fontSize: "1.3vw" }}>BUYERS</Typography>
              <Typography
                sx={{
                  fontSize: "1.3vw",
                  color:
                    Number(ratio?.chgValue?.replace("%", "")) >= 0
                      ? "#2B85DC"
                      : "#DE5B56",
                }}
              >
                {ratio?.chgValue}
              </Typography>
              <Typography sx={{ fontSize: "1.3vw" }}>SELLERS</Typography>
            </Box>
            <Box sx={{ width: "90%", px: 4 }}>
              <LinearProgress
                variant="determinate"
                value={Number(ratio?.Buyers?.match(/\d+/)[0])}
                style={{
                  backgroundColor: "#DE5B56",
                }}
                sx={{
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#2B85DC",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                px: 7,
                py: 1,
                boxSizing: "border-box",
              }}
            >
              <Typography sx={{ fontSize: "1.3vw", color: "#2B85DC" }}>
                {ratio?.Buyers}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <a href="https://www.bullionstats.com/">
                  <img
                    className={classes.bullionStatsImg}
                    src={bullionStats}
                    alt="bullionStats"
                  />
                </a>
              </Box>
              <Typography sx={{ fontSize: "1.3vw", color: "#DE5B56" }}>
                {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightPart}>
          <Box className={classes.header}>
            <img
              className={classes.leenLogoImg}
              src={leenLogo}
              alt="leenLogo"
            />
            <Box
              sx={{
                display: "flex",
                color: "#fff",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <AccessTimeIcon style={{ fontSize: "3vw" }} />
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "1.8vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {formatTime(time)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <CalendarMonthIcon style={{ color: "#fff", fontSize: "3vw" }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "#fff",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                  }}
                >
                  {formatDate(time)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    mt: -1,
                    ml: 0.5,
                  }}
                >
                  {formatDay(time)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className={classes.table}>
            <Box className={classes.tabeHeader}>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "left",
                  paddingLeft: 4,
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                Commodity
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: 2,
                  // background: "white",
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                Weight
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "left",
                  paddingLeft: 4,
                  alignItems: "center",
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                Buy
                <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                  &nbsp;AED
                </Typography>
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "left",
                  paddingLeft: 4,
                  alignItems: "center",
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                Sell
                <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                  &nbsp;AED
                </Typography>
              </Typography>
            </Box>

            <Box className={classes.tableContent}>
              {commodities?.map((commodity, idx) => {
                const words = commodity?.display_metal
                  ? commodity?.display_metal.split(" ")
                  : commodity?.commodity_title.split(" ");

                return (
                  <Box key={idx} className={classes.tableRow1}>
                    <Typography
                      style={{ fontSize: "1.5vw", fontWeight: "bold" }}
                      className={classes.tableRowColumn}
                    >
                      {words.map((word, index) => {
                        if (index === 0) {
                          return (
                            <span
                              key={index}
                              style={{ fontSize: "2vw", fontWeight: "bold" }}
                            >
                              {word.toUpperCase()}
                            </span>
                          );
                        } else {
                          return (
                            <span
                              key={index}
                              style={{
                                fontSize: "1vw",
                                marginTop: "0.2rem",
                                fontWeight: "bold",
                              }}
                            >
                              &nbsp;{word.toUpperCase()}
                            </span>
                          );
                        }
                      })}
                      &nbsp;
                      {commodity.commodity_title === "Silver" ||
                      commodity.commodity_title === "Platinum"
                        ? ""
                        : commodity.unitLabel === "TTB"
                        ? ""
                        : commodity.displayPurity}
                    </Typography>
                    <Typography
                      style={{
                        padding: 0,
                        fontSize: "2vw",
                        fontWeight: "bold",
                        justifyContent: "center",
                      }}
                      className={classes.tableRowColumn}
                    >
                      {commodity.unit}
                      {commodity.unitLabel}
                    </Typography>
                    <Typography
                      style={{ fontSize: "2vw", fontWeight: "bold" }}
                      className={classes.tableRowColumn}
                    >
                      {gold?.cur?.bid
                        ? commodityCalculation(
                            commodity.commodity_title === "Silver"
                              ? silver?.cur?.bid
                              : gold?.cur?.bid,
                            commodity.commodity_title === "Silver"
                              ? spread.silverBidSpread
                              : spread.goldBidSpread,
                            commodity.buy_premium,
                            3.674,
                            commodity.purity,
                            commodity.unit,
                            commodity.unitLabel === "TTB"
                              ? 116.64
                              : commodity.unitLabel === "KG"
                              ? 1000
                              : commodity.unitLabel === "OZ"
                              ? 31.1
                              : commodity.unitLabel === "TOLA"
                              ? 11.7
                              : 1,
                            commodity.buy_charge
                          )
                        : "0.00"}
                    </Typography>
                    <Typography
                      style={{ fontSize: "2vw", fontWeight: "bold" }}
                      className={classes.tableRowColumn}
                    >
                      {gold?.cur?.ask
                        ? commodityCalculation(
                            commodity.commodity_title === "Silver"
                              ? silver?.cur?.ask
                              : gold?.cur?.ask,
                            commodity.commodity_title === "Silver"
                              ? spread.silverAskSpread
                              : spread.goldAskSpread,
                            commodity.premium,
                            3.674,
                            commodity.purity,
                            commodity.unit,
                            commodity.unitLabel === "TTB"
                              ? 116.64
                              : commodity.unitLabel === "KG"
                              ? 1000
                              : commodity.unitLabel === "OZ"
                              ? 31.1
                              : commodity.unitLabel === "TOLA"
                              ? 11.7
                              : 1,
                            commodity.charges
                          )
                        : "0.00"}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesHeader}>
              <Typography
                sx={{
                  fontSize: "1.6rem",
                  pl: 2,
                  letterSpacing: "1px",
                }}
              >
                LEEN &nbsp; UPDATES
              </Typography>
            </Box>
            <Box className={classes.updatesContent}>
              <marquee behavior="" direction="">
                <Box sx={{ display: "flex" }}>
                  {adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "2vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Gold Price News
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "2vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openExpireSoon}
        onClose={handleExpireSoonClose}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <Box>
          <SubscriptionExpiringSoon
            date={adminData?.package.expire_date}
            handleClose={handleExpireSoonClose}
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired handleClose={handleCloseBlocked} />
          </Box>
        </Fade>
      </Modal>
      <Modal open={openBlocked}>
        <SubscriptionExpired handleClose={handleCloseBlocked} />
      </Modal>
    </div>
  );
};

export default Home;
