import { Box } from "@mui/system";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import { Typography } from "@mui/material";
export default function NoInternet() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "black",
      }}
    >
      <Box
        sx={{
          background: "#202741",
          p: 6,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#FFFFFF",
          gap: "5px",
          borderRadius: "15px",
        }}
      >
        <CloudOffOutlinedIcon sx={{ fontSize: "8vw" }} />
        <Typography
          sx={{
            fontSize: "2vw",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          No internet connection
        </Typography>
        <br />
        <Typography
          sx={{ fontSize: "1.5vw", textAlign: "center", lineHeight: 1.3 }}
        >
          Make sure wifi or cellular connection is turned on <br /> and then try
          again
        </Typography>
      </Box>
    </Box>
  );
}
